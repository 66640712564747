import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { message } from 'antd'
import api from "../../api";
import {BASE_URL} from "../../constant";

const WebsiteUpdate = () => {

    const [data, setData] = useState(null)
    const [value, setValue] = useState(null)

    const getWebsiteDetails = ()=>{
        // axios({
        //     method: "get",
        //     withCredentials: true,
        //     url: `book_of_trips/website/get-website`,
        // })
        api(BASE_URL)
            .get(`book_of_trips/website/get-website`)
            .then((res) => {
            setData(res.data.data)
            setValue((res.data.data?.isLive)? false : true)
        }).catch((err) => {
            console.log(err);
        });
    }

    useEffect(()=>{
        if(data === null){
            getWebsiteDetails()
        }
        // eslint-disable-next-line
    }, [])


    const updateWebsite = ()=>{
        axios({
            method: "POST",
            data: {
                id: '643937e9faf07617fcbc0bbd',
                isLive: value,
            },
            withCredentials: true,
            url: `book_of_trips/website/update-website`,
        }).then((res) => {
            message.success(res.data.message)
            getWebsiteDetails()
        }).catch((err) => {
            console.log(err);
        });
    }

  return (
    <div className='text-center p-5 Mon_font'>
        <h3>Connection: <strong className={`text-${(data?.isLive)? 'success' : 'danger'}`}>{(data?.isLive)? 'Success' : 'Bad'}</strong></h3>
        <button className='btn btn-sm btn-secondary px-5 py-2 my-3' onClick={updateWebsite}>Set Connection</button>
    </div>
  )
}

export default WebsiteUpdate
