import React, { useEffect, useState } from "react";
import OtherLayout from "../component/Layout/OtherLayout";
import "../../css/Style/About/About.css";
// import about from "../images/About/about.jpg";
// import instagram from "../images/About/instagram.png";
// import linkedin from "../images/About/linkedin.png";
// import OfferAndUpdates from "../component/LandingPage/OfferAndUpdates";
import Slider from "react-slick";
import '../../css/Style/Slider/Slider.css'
// import kashish from "../images/About/profile/Kashish.JPG";
// import amanjyot from "../images/About/profile/Amanjyot.JPG";
// import tarun from "../images/About/profile/Tarun.JPG";
// import yashika from "../images/About/profile/Yasika.JPG";
// import amandeep from "../images/About/profile/Amandeep.JPG";


const About = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  document.title = `BookOfTrips - ${"About Us"}`;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} Slider`}
        style={{
          ...style,
          background: "transparent",
          width: "50px",
          height: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "0%",
        }}
        onClick={onClick}
      >
        <i
          className="fa-solid fa-chevron-right"
          style={{ fontSize: "35px", color: "black", margin: "0 10px 0 0" }}
        ></i>
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} Slider`}
        style={{
          ...style,
          display: "block",
          background: "transparent",
          width: "50px",
          height: "50px",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "0%",
          zIndex: "10",
        }}
        onClick={onClick}
      >
        <i
          className="fa-solid fa-chevron-left"
          style={{ fontSize: "35px", color: "black", margin: "0 0 0 -25px" }}
        ></i>
      </div>
    );
  }
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: `${windowWidth < 800 ? "1" : "3"} `,
    slidesToScroll: 3,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3.2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const teamMembers = [
    {
      id: 1,
      name: "Mannmohit Singh",
      role: "Co-Founder",
      // image: `${kashish}`,
      // instagram: instagram,
      // linkedin: linkedin,
      desc: "Mannmohit Singh",
      // desc: "If we Have to Describe Kashish in one Line, She's a Super Friendly Trailblazer With a knack for cracking PJ's and a magnetic ability to strike up conversations on any topic, She brings an irresistible charm to the company. Equipped with a genuine passion for exploration and an infectious enthusiasm for creating unforgettable experiences, her charisma shines through every adventure we embark upon. We could say She Run’s the Entire show Single Handedly !",
      instaLink: "https://www.instagram.com/thebookoftrips/",
      // linkedinLink: "https://www.linkedin.com/in/kashish-batra-b86bb1168",
    },

    {
      id: 2,
      name: "Sahil",
      role: "Co Founder",
      // image: `${amanjyot}`,
      // instagram: instagram,
      // linkedin: linkedin,
      // desc: "Just like Mitochondria is the Power house of the Cell, we can say the same about aman. With Relevant experience in the Travel industry at a quite young age, His Mastery in  decision-making are unparalleled, allowing him to navigate complex situations with ease and precision. He demonstrates impressive maturity, consistently maintaining composure and rationality, even in the face of challenging circumstances. His ability to analyze and evaluate options enables him to make well-informed choices that yield successful outcomes.",
      // instaLink: "https://www.instagram.com/amanjyot_/",
      // linkedinLink: "https://www.linkedin.com/in/amanjyot-singh-90142118b",
    },

    // {
    //   id: 3,
    //   name: "S. Tarun",
    //   role: "Marketing Geek",
    //   // image: `${tarun}`,
    //   // instagram: instagram,
    //   // linkedin: linkedin,
    //   desc: "Meet Tarun, the Marketing Maestro! With a boundless creative mindset, he takes marketing to new heights. As a natural leader, Tarun infuses every interaction with a touch of humor, effortlessly turning even the most daunting tasks into a breeze. With unwavering focus on goals, he emerges as the driving force behind our marketing success. Brace yourself for the enchantment that unfolds as Tarun weaves his marketing spells across all our social media channels!",
    // },

    // {
    //   id: 4,
    //   name: "Yashika Taneja",
    //   role: "Sales Consultant",
    //   // image: `${yashika}`,
    //   // instagram: instagram,
    //   // linkedin: linkedin,
    //   desc: "Introducing Yashika, the dynamic powerhouse driving our sales team. With her infectious energy and boundless creativity, she injects vitality into every interaction. Guided by the company's vision, she understands that providing impeccable guidance to clients is the key to aligning successful sales effortlessly.",
    // },

    // {
    //   id: 5,
    //   name: "Amandeep Kaur",
    //   role: "Ground Operation",
    //   // image: `${amandeep}`,
    //   // instagram: instagram,
    //   // linkedin: linkedin,
    //   desc: "Introducing Amandeep, our adorable operations executive, who effortlessly spreads joy and happiness wherever she goes. With an innate ability to make everyone around her light up with delight, her presence creates a warm and inviting atmosphere. She is a true expert in her field, leaving no detail overlooked. From coordinating intricate logistics to ensuring flawless execution, her deep knowledge and expertise shine through in every aspect of her work,  delivering exceptional travel experiences.",
    // },
  ];
  return (
    <>
      <OtherLayout
        // background={about}
        title="Our Mission"
        subTitle="Forging a new path for the future of Travel"
      >
        <div
          className="py-5 Mon_font"
          style={{ backgroundColor: "rgb(236, 236, 236)" }}
        >
          <h3 className={`fw-bold text-center`}>We are BookOfTrips</h3>
          <div className={`text-center ${windowWidth < 992 ? "mb-4" : "mb-4"}`}>
            <div className="hr-line text-center"></div>
          </div>
          <div className=" d-flex justify-content-evenly align-items-start mt-5 px-3">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <i className="fa-solid fa-star text-secondary fs-2"></i>
              <p className="m-0 mt-2 text-secondary py-1 text-center about-small-text">
                500+
              </p>
              <p className="m-0 text-secondary py-1 text-center about-small-text">
                5 Star {windowWidth < 500 ? <br /> : null} Ratings
              </p>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <i className="fa-solid fa-indian-rupee-sign text-secondary fs-2"></i>
              <p className="m-0 mt-2 text-secondary py-1 text-center about-small-text">
                Best Price
              </p>
              <p className="m-0 text-secondary py-1 text-center about-small-text">
                Assured
              </p>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <i className="fa-solid fa-book text-secondary fs-2"></i>
              <p className="m-0 mt-2 text-secondary py-1 text-center about-small-text">
                1000+
              </p>
              <p className="m-0 text-secondary py-1 text-center about-small-text">
                Successful {windowWidth < 500 ? <br /> : null} Trips
              </p>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <i className="fa-solid fa-indian-rupee-sign text-secondary fs-2"></i>
              <p className="m-0 mt-2 text-secondary py-1 text-center about-small-text">
                100%
              </p>
              <p className="m-0 text-secondary py-1 text-center about-small-text">
                Best Price {windowWidth < 500 ? <br /> : null} Assured
              </p>
            </div>
          </div>
        </div>
        <div className="container p-lg-5  p-3 py-4 mt-4 text-center Mon_font">
          <h3 className={`fw-bold text-center`}>Our Journey</h3>
          <div className={`text-center ${windowWidth < 992 ? "mb-4" : "mb-4"}`}>
            <div className="hr-line text-center"></div>
          </div>
          <p className="m-0 text-secondary text-start px-3">
            <div
              style={{
                fontSize: `${windowWidth < 500 ? "12px" : null}`,
                textAlign: "justify",
                textJustify: "inter-word",
              }}
            >
              Hi! I am Book Of Trips. So let me tell you my story.
              <br />
            </div>{" "}
            <br />
            <div
              style={{
                fontSize: `${windowWidth < 500 ? "12px" : null}`,
                textAlign: "justify",
                textJustify: "inter-word",
              }}
            >
              Once upon a time, there was a chatterbox. His name was Sahil. He planned a trip with one of his friends from school and another one from college. They went to Chopta Chandrashilla. They brought back amazing memories but most importantly, the 'Dil Chahta Hai' team was born. <br />
            </div>{" "}
            <br />
            <div
              style={{
                fontSize: `${windowWidth < 500 ? "12px" : null}`,
                textAlign: "justify",
                textJustify: "inter-word",
              }}
            >
              Months and years passed and one day the school guy, whose name was MSK, came up with an idea to start a travel page and spend the rest of their lives travelling. As both of them were unemployed, no one gave a second thought and I was born without any intercourse. Ouch!
              <br />
            </div>{" "}
            <br />
            <div
              style={{
                fontSize: `${windowWidth < 500 ? "12px" : null}`,
                textAlign: "justify",
                textJustify: "inter-word",
              }}
            >
              Trippy Wheelz, Tripkhor, BCTrip, TravelwithMaya, Travelbhakt, and many other names popped in their mind but finally they named me “Book of Trips”. <br />
            </div>{" "}
            <br />

            <div
                style={{
                  fontSize: `${windowWidth < 500 ? "12px" : null}`,
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
            >
              They had numbers of meetings to discuss my future but they rather used to spend more hours playing cards. Earlier I didn’t like both of them but then the trips started and the way they used to entertain people, make them laugh, take care of each and every one, made me realize that yes, they are the best founders/fathers any travel agency can get!
              <br />
            </div>{" "}
          </p>
        </div>

        <div className="container p-lg-5  p-3 py-4 mt-4 text-center Mon_font">
          <h3 className={`fw-bold text-center`}>Why Us</h3>
          <div className={`text-center ${windowWidth < 992 ? "mb-4" : "mb-4"}`}>
            <div className="hr-line text-center"></div>
          </div>
          <p className="m-0 text-secondary text-start px-3">
            <div
                style={{
                  fontSize: `${windowWidth < 500 ? "12px" : null}`,
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
            >
              Hi! I am Book of Trips. My focus is to write the most amazing chapter of your life by not just making your travel easy but also by making you celebrate your journey.
              <br />
            </div>{" "}
            <br />
            <div
                style={{
                  fontSize: `${windowWidth < 500 ? "12px" : null}`,
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
            >
              I believe that I am the Shakespeare of the travel and tourism industry. I put all my efforts to make your chapter of the trip, the most amazing one which you can read again and again on your picture gallery.
              <br />
            </div>{" "}
            <br />
            <div
                style={{
                  fontSize: `${windowWidth < 500 ? "12px" : null}`,
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
            >
              I have two founders. The first one has done PhD in lame jokes and interactive fun games. Such a brilliant course! The second one is the human version of a radio, he can speak and sing for 48 hours in a day!
              <br />
            </div>{" "}
            <br />
            <div
                style={{
                  fontSize: `${windowWidth < 500 ? "12px" : null}`,
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
            >
              I have appointed them  to host your journey from happy to happiest.
              They later appointed most happening people in their team to support our mission :)
              <br />
            </div>{" "}
            <br />
          </p>
        </div>
        <div
          className=" d-flex justify-content-evenly align-items-start px-3 py-5 Mon_font"
          style={{ backgroundColor: "rgb(236, 236, 236)" }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <i className="fa-solid fa-indian-rupee-sign text-secondary fs-2"></i>
            <p className="m-0 text-secondary py-1 text-center about-small-text mt-2">
              Best {windowWidth < 500 ? <br /> : null} Price Assured
            </p>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <i className="fa-solid fa-cart-shopping text-secondary fs-2"></i>
            <p className="m-0 text-secondary py-1 text-center about-small-text mt-2">
              Flexible {windowWidth < 500 ? <br /> : null} Cancellation{" "}
              {windowWidth < 500 ? <br /> : null} Policy
            </p>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <i className="fa-solid fa-user text-secondary fs-2"></i>
            <p className="m-0 text-secondary py-1 text-center about-small-text mt-2">
              Experienced Trip {windowWidth < 500 ? <br /> : null}
              Captains{" "}
            </p>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <i className="fa-solid fa-message text-secondary fs-2"></i>
            <p className="m-0 text-secondary py-1 text-center about-small-text mt-2">
              Certified {windowWidth < 500 ? <br /> : null} Travel{" "}
              {windowWidth < 500 ? <br /> : null} Consultants
            </p>
          </div>
        </div>

        {/* <div className="team__member p-5 px-4 text-center Mon_font">
                    <h4 className={`fw-bold text-center ${windowWidth < 500? 'fs-5' : 'fs-4'}`}>Meet Our Team. The ideal set <br /> of extraordinary people</h4>
                    <div className={`${windowWidth < 800? null : 'd-flex' } mt-5`}>
                        <div className={` ${windowWidth < 500 ? null : 'mx-5'}`}>
                            <img className='shd p-0 rounded-pill' width={150} height={150} src={profile} alt=""/>
                            <p className='m-0 text-center fw-bold fs-4 mt-2'>Govind Gaur</p>
                            <p className='m-0 text-secondary text-center about-small-text fs-5'>CEO, Founder</p>
                            <div className='mt-2'>
                                <img className='p-1' style={{cursor: 'pointer'}} width={40} height={40} src={instagram} alt="" />
                                <img className='p-1' style={{cursor: 'pointer'}} width={40} height={40} src={linkedin} alt="" />
                            </div>
                            <div className={`text-center ${windowWidth < 992 ? 'mb-4' : 'mb-4'}`}>
                                <div className="hr-line text-center"></div>
                            </div>
                            <div>
                                <p className={`m-0 ${windowWidth < 800 ? null : 'ps-5'} text-secondary text-start fs-6`}>Gone are the days when our career aspirations were limited to the conventional options of scientist, teacher, or doctor. Today, we live in a world where a career can be anything that solves problems for society. This realization led a group of engineers from NIT Kurukshetra to identify a gap in India's travel industry and set out to fill it with a fresh burst of young energy. They recognized that the dispersed agent-based model of travel needed a revamp to become more accessible, transparent, and traveler-friendly. And thus, WanderOn was born. Join us as we take a closer look at the trailblazers who are dedicated to creating unforgettable travel experiences for people.</p>
                            </div>
                        </div>
                        <hr />
                        <div className={` ${windowWidth < 500 ? null : 'mx-5'}`}>
                            <img className='shd p-0 rounded-pill' width={150} height={150} src={profile} alt=""/>
                            <p className='m-0 text-center fw-bold fs-4 mt-2'>Govind Gaur</p>
                            <p className='m-0 text-secondary text-center about-small-text fs-5'>CEO, Founder</p>
                            <div className='mt-2'>
                                <img className='p-1' style={{cursor: 'pointer'}} width={40} height={40} src={instagram} alt="" />
                                <img className='p-1' style={{cursor: 'pointer'}} width={40} height={40} src={linkedin} alt="" />
                            </div>
                            <div className={`text-center ${windowWidth < 992 ? 'mb-4' : 'mb-4'}`}>
                                <div className="hr-line text-center"></div>
                            </div>
                            <div>
                                <p className={`m-0 ${windowWidth < 800 ? null : 'ps-5'} text-secondary text-start fs-6`}>Gone are the days when our career aspirations were limited to the conventional options of scientist, teacher, or doctor. Today, we live in a world where a career can be anything that solves problems for society. This realization led a group of engineers from NIT Kurukshetra to identify a gap in India's travel industry and set out to fill it with a fresh burst of young energy. They recognized that the dispersed agent-based model of travel needed a revamp to become more accessible, transparent, and traveler-friendly. And thus, WanderOn was born. Join us as we take a closer look at the trailblazers who are dedicated to creating unforgettable travel experiences for people.</p>
                            </div>
                        </div>
                    </div>
                </div> */}

        <div className="team__member p-5 px-4 text-center Mon_font">
          <h4
            className={`fw-bold text-center ${
              windowWidth < 500 ? "fs-5" : "fs-4"
            }`}
          >
            Meet Our Dream Team!
          </h4>
          <Slider {...settings}>
            {teamMembers.map((item) => {
              return (
                <>
                  <div
                    className={`${windowWidth < 800 ? null : "d-flex"} mt-5`}
                  >
                    <div className={` ${windowWidth < 500 ? "mx-4" : "mx-2"}`}>
                      <div className="d-flex justify-content-center align-items-center">
                        <img
                          className="shd p-0 rounded-pill"
                          width={150}
                          height={150}
                          src={item.image}
                          alt=""
                        />
                      </div>
                      <p className="m-0 text-center fw-bold fs-5 mt-2">
                        {item.name}
                      </p>
                      <p className="m-0 text-secondary text-center about-small-text fs-6">
                        {item.role}
                      </p>
                      <div className="mt-2 d-flex justify-content-center align-items-start">
                        <a href={item.instaLink && item.instaLink}>
                          <img
                            className="p-1"
                            style={{ cursor: "pointer" }}
                            width={40}
                            height={40}
                            // src={instagram}
                            alt=""
                          />
                        </a>
                        <a href={item.linkedinLink && item.linkedinLink}>
                          <img
                            className="p-1"
                            style={{ cursor: "pointer" }}
                            width={40}
                            height={40}
                            // src={linkedin}
                            alt=""
                          />
                        </a>
                      </div>
                      <div
                        className={`text-center ${
                          windowWidth < 992 ? "mb-4" : "mb-4"
                        }`}
                      >
                        <div className="hr-line text-center"></div>
                      </div>
                      <div className="d-flex">
                        <div
                          className={`m-0 ${
                            windowWidth < 800 ? null : "ps-5 pe-3"
                          } text-secondary text-start w-100`}
                          style={{ fontSize: `${windowWidth < 800 && "12px"}` }}
                        >
                          {item.name === "Kashish Batra" ? (
                            <>
                              <div
                                className="mb-3"
                                style={{
                                  textAlign: "start",
                                  textJustify: "inter-word",
                                }}
                              >
                                If we Have to Describe Kashish in one Line,
                                She's a Super Friendly Trailblazer
                              </div>
                              <div
                                style={{
                                  textAlign: "start",
                                  textJustify: "inter-word",
                                }}
                              >
                                With a knack for cracking PJ's and a magnetic
                                ability to strike up conversations on any topic,
                                She brings an irresistible charm to the company.
                                Equipped with a genuine passion for exploration
                                and an infectious enthusiasm for creating
                                unforgettable experiences, her charisma shines
                                through every adventure we embark upon. We could
                                say She Run’s the Entire show Single Handedly!
                              </div>
                            </>
                          ) : (
                            <>
                              <div style={{ textAlign: "start" }}>
                                {item.desc}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </Slider>
        </div>
        <div className="container Mon_font text-center about-5000">5,000+</div>
        {/*<OfferAndUpdates />*/}
      </OtherLayout>
    </>
  );
};

export default About;
