import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import TalkToExpert from '../component/LandingPage/TalkToExpert';
import Testmonials from '../component/LandingPage/Testmonials';
import OtherLayout from '../component/Layout/OtherLayout';
import axios from 'axios';
// import noresult from '../images/noresult.png'
import '../../css/Style/CategoryListPage/Category.css'
import {BASE_URL} from "./constant";
import api from "./api";
import {getAllTrips} from "../util/FetchTrips";
import {getAllCategoryData} from "../util/GetAllCategory";

const MonthCategoryListPage = () => {
    const [trip, setTrip] = useState(null)

    const [category, setCategory] = useState(null)
    const [categoryBackground, setCategoryBackground] = useState(null)
    const [month, setMonth] = useState(null)
    const [duration, setDuration] = useState(null)
    const [trending, setTrending] = useState(null)
    const params = useParams();
    const navigate = useNavigate();
    const paramscategory = (params?.id).replace(/-/g, " ");
    document.title = `BookOfTrips - ${paramscategory}`

    const headingFull = getFullMonthName(paramscategory)
    // get all category
    const getAllCategory = async () => {
        const host = BASE_URL;
        await getAllCategoryData(BASE_URL).then((res) => {
                const image = res?.filter((category) => {
                    let data
                    // console.log("category: ", category)
                    // console.log("paramscategory: ", paramscategory)

                    if (category.name.toUpperCase() === paramscategory.toUpperCase() || category.name.toUpperCase() === headingFull.toUpperCase()) {
                        data = category
                    }
                    return data
                })
                // console.log("image ", image)
                setCategoryBackground(image[0])
            }).catch((err) => {
                console.log(err);
            });
    }

    function getFullMonthName(shortForm) {
        const monthMap = {
            Jan: "January",
            Feb: "February",
            Mar: "March",
            Apr: "April",
            May: "May",
            Jun: "June",
            Jul: "July",
            Aug: "August",
            Sep: "September",
            Oct: "October",
            Nov: "November",
            Dec: "December"
        };

        // Convert the short form to Title Case to match the keys in the map
        const titleCaseShortForm = shortForm.charAt(0).toUpperCase() + shortForm.slice(1).toLowerCase();

        return monthMap[titleCaseShortForm] || shortForm;
    }

    function getMonthFromInput(input) {
        const monthMap = {
            jan: 0, january: 0,
            feb: 1, february: 1,
            mar: 2, march: 2,
            apr: 3, april: 3,
            may: 4,
            jun: 5, june: 5,
            jul: 6, july: 6,
            aug: 7, august: 7,
            sep: 8, sept: 8, september: 8,
            oct: 9, october: 9,
            nov: 10, november: 10,
            dec: 11, december: 11
        };

        // Normalize the input to lowercase to handle different cases
        const normalizedInput = input.toLowerCase();

        // Return the month index if the input is valid, otherwise return -1 or throw an error
        if (monthMap.hasOwnProperty(normalizedInput)) {
            return monthMap[normalizedInput];
        } else {
            return -1; // or you can throw an error like: throw new Error("Invalid month input");
        }
    }

    useEffect(() => {
        // axios({
        //     method: "get",
        //     withCredentials: true,
        //     url: `api/cityImages/get-all-city-image`,
        // }
        const host = BASE_URL;
        api(host)
            .get(`book_of_trips/cityImages/get-all-city-image`)
            .then((res) => {
                let arr = res.data.data;
                const data = arr.find(item => item.city.toUpperCase() === paramscategory);
                setTrending(data?.image)
            }).catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (paramscategory) {
            getAllCategory();
        }
        // eslint-disable-next-line
    }, [paramscategory])

    // ==================== UNIQUE MONTH AND THEN FILTERING WITH STATE MONTH
    const allDates = [];
    const box = category?.map(obj => obj.dates.map(dateObj => allDates.push(dateObj)))
    const uniqueMonths = Array.from(new Set(allDates.map(obj => new Date(obj.date).toLocaleString('default', { month: 'long' })))).sort((a, b) => new Date(Date.parse('01 ' + a + ' 2000')) - new Date(Date.parse('01 ' + b + ' 2000')));
    const filterMonthData = category?.filter(obj => {
        return obj.dates.some(item => {
            const dateObj = new Date(item.date);
            return dateObj.toLocaleString('default', { month: 'long' }) === month;
        });
    });
    // ==================== UNIQUE MONTH AND THEN FILTERING WITH STATE MONTH

    // ==================== FILTERING WITH STATE DURATION
    const filterTrip = category?.filter(item => item.duration?.includes(duration))
    // ==================== FILTERING WITH STATE DURATION

    // get All trips
    const AllTrips = async () => {
        const host = BASE_URL;
        await getAllTrips(BASE_URL).then((res) => {
                setTrip(res);
                setCategory(res?.filter(trip => {
                    // const isMatch = trip.category.some(category => category.toUpperCase() === paramscategory.toUpperCase());
                    return true
                }));
            }).catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        AllTrips();
        // eslint-disable-next-line
    }, [paramscategory])

    const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    const [monthFilter, setMonthFilter] = useState('all');
    const [durationFilter, setDurationFilter] = useState('all');
    const monthOptions = [...new Set(category?.flatMap((item) => item.dates.map((date) => date.date.slice(5, 7))))].map(
        (month) => <option value={month}>{monthNames[month - 1]}</option>
    );

    // --------------- START ---- shorting the all trips duration ------------------
    const durationOptions = [...new Set(category?.map((item) => item.duration))].sort((a, b) => {
        const [nightsA, daysA] = a.split('N-');
        const [nightsB, daysB] = b.split('N-');

        if (parseInt(nightsA) !== parseInt(nightsB)) {
            return parseInt(nightsA) - parseInt(nightsB);
        } else {
            return parseInt(daysA) - parseInt(daysB);
        }
    }).map((duration) => (
        <option value={duration}>{duration}</option>
    ));
    // --------------- END ------------------


    function filterData() {
        let currentSelectedMonth = getMonthFromInput(paramscategory)
        return category?.filter(item => {

            if (monthFilter !== 'all') {
                // Check if any date in the item matches the selected month
                if (!item.dates.some(dateObj => new Date(dateObj.date).getMonth() === parseInt(monthFilter) - 1)) {
                    return false;
                }
            }
            if (durationFilter !== 'all' && item.duration !== durationFilter) {
                return false;
            }

            if ((currentSelectedMonth !== -1) && !(item.dates.some(dateObj => new Date(dateObj.date).getMonth() === currentSelectedMonth))) {
                return false;
            }

            return true;
        });
    }

    const filteredData = filterData();
    console.log("filteredData: ",filteredData)

    function formatPrice(activeTrip) {
        let minPrice = activeTrip?.costing?.length ?
            activeTrip.costing.reduce((min, obj) => {
                return parseFloat(obj?.price) < parseFloat(min) ? parseFloat(obj?.price) : parseFloat(min);
            }, Infinity) : null;

        let formattedMinPrice = (minPrice !== null && minPrice !== Infinity) ? parseInt(minPrice, 10).toLocaleString("en-IN") : 'N/A';


        return formattedMinPrice
    }

    // const background = categoryBackground !== null ? categoryBackground.url :

    return (
        <>
            {
                <OtherLayout background={categoryBackground?.url || trending} title={headingFull.charAt(0).toUpperCase() + headingFull.slice(1)} subTitle={`BookOfTrips is a way for your happiness`}>
                    <div className="category__cont container-fluid px-lg-5 px-4 Mon_font my-5">
                        {
                            category?.length > 0 &&
                            <div>
                                <h2 className='text-start category__heading mb-4 fw-bold'>Our {paramscategory.charAt(0).toUpperCase() + paramscategory.slice(1)} packages</h2>{/* =============== MONTH FILTER ===============   */}
                            </div>
                        }
                        <div className="row">
                            {
                                filteredData?.length === 0 ?
                                    <div className='text-center'>
                                        {/*<img src={noresult} alt="no-result" />*/}
                                        <h5 className='text-secondary text-center Mon_font'>No Trips Found!</h5>
                                    </div>
                                    :
                                    filteredData?.map((item) => {
                                        return (
                                            <>
                                                {
                                                    <div key={item.id} onClick={() => navigate(`/${(item?.trip_url.toLowerCase()).replace(/\s+/g, '-')}`)} className="text-center trip__container col-12 col-sm-12 col-lg-4 col-md-6 mx-lg-4">
                                                        <div className="trip__img__container">
                                                            <img src={item.image} alt="" className="trip__img__container" />
                                                            <div className="trip__price__tag_custom"><small>Starts at ₹{formatPrice(item)}/- </small></div>
                                                        </div>

                                                        <div className="trips text-start other__content">
                                                            <div className='d-flex justify-content-between'>
                                                                <p className='date__time p-0 mt-1'><i className="fa-solid fa-location-dot me-1"></i>{item.departure}</p>
                                                                <p className='date__time p-0 mt-1'><i className='fa-regular fa-clock me-1'></i>{item.duration}</p>
                                                            </div>
                                                            <h5 className="trip__title my-1">{item.heading}</h5>
                                                            <div className='dates Mon_font  mt-2 '>
                                                                <div className='d-flex flex-wrap justify-content-start align-items-center'>
                                                                    <div className=''>
                                                                        <i className="fa-solid fa-calendar-days me-2"></i>
                                                                    </div>
                                                                    {1 === 1 && (item.dates.filter(obj => {
                                                                        return new Date(obj.date) >= new Date()})).map(item =>

                                                                        <div className='me-2'>
                                                                            <p className='m-0'>
                                                                                {new Date(item.date).toLocaleDateString('default', { day: 'numeric', month: 'short' })},
                                                                            </p>
                                                                        </div>).slice(0, 10)
                                                                    }
                                                                </div>
                                                                {item.customised === 'true' &&
                                                                    <div className='m-0 me-1 mt-3'>
                                                                        <p className='m-0'>Customised Trip</p>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        )
                                    })
                            }
                        </div>
                    </div>
                    <TalkToExpert />
                    {/*<div className='my-5'>*/}
                    {/*    <Testmonials />*/}
                    {/*</div>*/}
                </OtherLayout>
            }
        </>
    )
}

export default MonthCategoryListPage
