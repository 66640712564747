import React from 'react'
import BlackHeader from '../component/Header/BlackHeader'
import Footer from '../component/Footer/Footer'
import '../../css/Style/Terms&Policy/Style.css'

const TermsPage = () => {
    return (
        <>
            <BlackHeader />
            <div className='terms__page Mon_font container my-5'>
                <h1 className='mb-3 text-uppercase fs-3 fs-sm-3 fs-lg-4 text-start'>Terms and conditions</h1>
                <p>
                    - The photos/videos content created on BookOfTrips’s trip (by BookOfTrips’s content creators or clients) is the property of BookOfTrips and can only be used by BookOfTrips Experiences for advertising across media platforms. None of the digital content can be used by anyone for advertising/commercial use without obtaining the rightful permissions by BookOfTrips.
                </p>
                <p>
                    - Full Payment of the trip cost must be completed before the trip begins. Pending Payments may eventually lead to the Cancellation of the trip.
                </p>
                <p>
                    - The IDs shall all be verified before boarding. No boarding shall be entertained without a valid Govt. ID.
                </p>
                <p>
                    - The Transfer of the bookings is not permitted. Only the names mentioned at the time of confirmation shall be allowed to travel.
                </p>
                <p>
                    - People travelling with BookOfTrips for International travel are advised that their passport must be valid for at least six (6) months from the date of intended travel and not damaged in any way. BookOfTrips does not take any responsibility for clients who book travel with passports that do not meet this requirement. It is the responsibility of the client to ensure that their passport is valid for the required period before making any travel bookings through BookOfTrips. Any denial of entry at immigration, and any additional cost arising out of alternate arrangements for deporting has to be borne by the travellers.
                </p>
                <p>
                    - No refunds shall be made towards any inclusion(s) not availed by the Client.
                </p>
                <p>
                    - Travelers must take care of their luggage & belongings. The management shall not be responsible for any damage or any missing items along the tour.
                </p>
                <p>
                    - The time of Departure is stated & fixed. All travelers must update their status with the Trip coordinator(s), & report at the pickup point 30 mins prior to the scheduled departure.
                </p>
                <p>
                    - The Air Conditioning will be switched off in the hills. Also, during the trip, it shall be the Driver’s discretion to put off the AC as & when required, considering the travelers’ safety & ease of travel along uneven & dangerous routes.
                </p>
                <p>
                    - Our time of departure is fixed must depart by the stated time & keep their status updated with the trip coordinator(s). Anyone missing the bus shall not be eligible for any refunds. We shall call you twice before the schedule departure.
                </p>
                <p>
                    - Drinking & Smoking are strictly prohibited along the tour due to health & safety concerns. Our tours involve physically demanding activities such as trekking, camping at high altitudes and we recommend for you to be in the right shape to make most of them.
                </p>
                <p>
                    - BookOfTrips is a modern travel community, organizes and arranges the adventure trip to the mountains which has the risk of accidents, loss of life, bodily injury, financial repercussions, etc. Neither the BookOfTrips nor its agents or affiliated entities shall be responsible or liable for any accident, bodily injury, illness or death, loss or damage to baggage or property, or for any damages or claims whatsoever arising from loss (including loss of possessions and loss of enjoyment), negligence or delay from the act, error, omission default or negligence of any person not its direct employee or under its exclusive control.
                </p>
                <p>
                    - No act of misconduct or indiscipline shall be tolerated on the tours. We are a cordial travel community and we aspire to bring to you a hassle free and memorable experience.
                </p>
                <p>
                    - BookOfTrips shall not be responsible for any delays or alterations in the program or indirectly incurred expenses in cases such as Natural Hazards, accident, breakdown of machinery, weather conditions, landslides, political closure or any untoward incidents.
                </p>
                <p>
                    - We do not provide any insurance policy to cover for the expenditure on sickness or accidents or losses incurred due to theft or other reasons.
                </p>
                <p>
                    - Numerous factors such as weather and road conditions the physical ability of participants etc. may bring alteration in the itinerary. We reserve the right to make necessary changes in the schedule in the interest of safety, comfort and general well-being!
                </p>
            </div>
            <Footer />
        </>
    )
}

export default TermsPage
