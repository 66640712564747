import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../../css/Style/Footer/Footer.css";
import axios from "axios";
import {BASE_URL} from "../../pages/constant";
import api from "../../pages/api";
import {getAllTrips} from "../../util/FetchTrips";
import {getAllCategoryData} from "../../util/GetAllCategory";

const Footer = () => {
  const [allCategory, setAllCategory] = useState(false);

  // get all category
  const getAllCategory = async () => {

    await getAllCategoryData(BASE_URL).then((res) => {
        if (res) {
          setAllCategory(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllCategory();
  }, []);

  return (
    <>
      <div className="footer text-white text-center">
        <div className="page__links fs-6 d-flex flex-column flex-lg-row">
          <Link className="fs-6" to="/terms-and-conditions">
            Terms & Conditions
          </Link>
          <Link className="fs-6" to="/privacy-policy">
            Privacy Policy
          </Link>
          <Link className="fs-6" to="/refund-policy">
            Cancellation Policy
          </Link>
          <Link className="fs-6" to="/contact">
            Contact Us
          </Link>
          {/*<Link className="fs-6" to="/career">*/}
          {/*  Careers*/}
          {/*</Link>*/}
        </div>
        {/*<div className="trip__locations d-flex flex-column flex-lg-row">*/}
        {/*  {allCategory &&*/}
        {/*    allCategory*/}
        {/*      ?.map((obj) => {*/}
        {/*        return (*/}
        {/*          <li key={obj.id}>*/}
        {/*            <Link to={`/category/${obj.name.replace(/\s+/g, "-")}`}>*/}
        {/*              {obj.name}*/}
        {/*            </Link>*/}
        {/*          </li>*/}
        {/*        );*/}
        {/*      })*/}
        {/*      .slice(allCategory.length - 4, allCategory.length)}*/}
        {/*</div>*/}
      </div>
      <div className="text-center social__media__links mt-5 mb-4">
        <Link to="https://www.instagram.com/thebookoftrips" target={"_blank"}>
          <i className="fa-brands fa-instagram"></i>
        </Link>
        <Link to="https://api.whatsapp.com/send?phone=917015415229&text=Hey%BookOfTrips%2C%20I%20was%20exploring%20your%20website%20and%20i%20had%20some%20queries." target={"_blank"}>
          <i className="fa-brands fa-whatsapp"></i>
        </Link>
        <Link to="https://www.facebook.com/bookoftrips" target={"_blank"}>
          <i className="fa-brands fa-facebook"></i>
        </Link>

      </div>
      <div className="Mon_font text-center footer__address">
        <h6>ADDRESS</h6>
        <p>BookOfTrips 38/1 double storey, Tilak Nagar New Delhi 110018</p>
        <div className="d-flex flex-column text-center justify-content-between email__number__website">
          <p>thebookoftrips@gmail.com</p>
          <span><i className='me-2 fa-solid fa-phone'></i><Link to="tel:+917015415229">+917015415229</Link> , <Link to="tel:+918287367709">+918287367709</Link> , <Link to="tel:+917011853097">+917011853097</Link> </span>
          <p>www.bookoftrips.in</p>
        </div>
      </div>
      <div className="text-center copyright__text mt-3">
        <h5>© Copyright by BookOfTrips</h5>
      </div>
    </>
  );
};

export default Footer;
