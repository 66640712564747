import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import '../../../css/Style/BookingPage/Success.css'

const Success = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(10);
  
  // Decode the URL parameters
  const decodedId = decodeURIComponent(id);
  const data = decodedId.split('_');
  
  // Extract data for easier access
  const paymentInfo = {
    transactionId: data[0],
    username: data[1],
    timestamp: data[2],
    phoneNumber: data[3],
    tripName: data[4],
    advanceAmount: parseFloat(data[5]),
    email: data[6],
    bookingId: data[7],
    tripDate: data[8],
    duration: data[9],
    location: data[10],
    bookingDetails: data[11],
  };

  const toUpperCase = (string) => {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      maximumFractionDigits: 0
    }).format(amount);
  };

  const sendMessageToWhatsApp = () => {
    const message = encodeURIComponent(
      `Hello BookOfTrips, My name is *${toUpperCase(paymentInfo.username)}* and I successfully paid for a trip to *${toUpperCase(paymentInfo.tripName)}*. My transaction id ${paymentInfo.transactionId} and my booking details *${paymentInfo.bookingDetails}*`
    );
    const phoneNumber = '+917015415229';
    const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`;
    window.open(whatsappLink, '_blank');
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 5000);

    const redirectTimer = setTimeout(() => {
      clearInterval(timer);
      navigate('/');
    }, 20000);

    return () => {
      clearInterval(timer);
      clearTimeout(redirectTimer);
    };
  }, [navigate]);

  if (!data || data.length < 13) {
    return (
      <div className="success-container">
        <div className="success-header">
          <h2 className="success-title" style={{ color: '#dc3545' }}>Invalid payment data</h2>
          <Link to="/" className="home-link">Return to Home</Link>
        </div>
      </div>
    );
  }

  return (
    <div className="success-container">
      <div className="success-header">
        <div className="success-icon">
          <i className="fas fa-check-circle"></i>
        </div>
        <h2 className="success-title">Payment Successful!</h2>
        <p className="success-subtitle">
          Your booking is confirmed. We have sent you an email with the trip details.
          Our team member will contact you shortly.
        </p>
      </div>

      <div className="success-card">
        <div className="card-section">
          <h5 className="section-title">Trip Details</h5>
          <div className="details-grid">
            <div className="detail-item">
              <div className="detail-label">Trip Name:</div>
              <div className="detail-value">{toUpperCase(paymentInfo.tripName)}</div>
            </div>
            
            <div className="detail-item">
              <div className="detail-label">Trip Date:</div>
              <div className="detail-value">{paymentInfo.tripDate}</div>
            </div>

            <div className="detail-item">
              <div className="detail-label">Duration:</div>
              <div className="detail-value">{paymentInfo.duration}</div>
            </div>

            <div className="detail-item">
              <div className="detail-label">Location:</div>
              <div className="detail-value">{paymentInfo.location}</div>
            </div>
          </div>
        </div>

        <div className="card-section">
          <h5 className="section-title">Payment Details</h5>
          <div className="details-grid">
            
            <div className="detail-item">
              <div className="detail-label">Advance Paid:</div>
              <div className="detail-value amount-paid">{formatCurrency(paymentInfo.advanceAmount)}</div>
            </div>
            
          </div>
        </div>

        <div className="card-section">
          <h5 className="section-title">Booking Details</h5>
          <div className="details-grid">
            <div className="detail-item">
              <div className="detail-label">Transaction ID:</div>
              <div className="detail-value">{paymentInfo.transactionId}</div>
            </div>
            
            
            <div className="detail-item">
              <div className="detail-label">Contact Number:</div>
              <div className="detail-value">{paymentInfo.phoneNumber}</div>
            </div>
            
            <div className="detail-item">
              <div className="detail-label">Email:</div>
              <div className="detail-value">{paymentInfo.email}</div>
            </div>
            
            <div className="detail-item">
              <div className="detail-label">Booking Time:</div>
              <div className="detail-value">{paymentInfo.timestamp}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="countdown-text">
        Redirecting to Home Page in{' '}
        <span className="countdown-number">{countdown} seconds</span>
      </div>
      
      <div className="button-container">
        <button onClick={sendMessageToWhatsApp} className="success-container-whatsapp-button">
          <i className="fab fa-whatsapp"></i>
          Contact on WhatsApp
        </button>
        
        <div className="divider">or</div>
        
        <Link to="/" className="home-link">
          <i className="fas fa-home"></i>
          Return to Home Page
        </Link>
      </div>
    </div>
  );
};

export default Success;