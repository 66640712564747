import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import axios from 'axios'
import '../../../css/Style/LandingPage/PromotionBanner.css'
import { Link, useNavigate } from 'react-router-dom'
import {BASE_URL} from "../../pages/constant";
import api from "../../pages/api";

const PromotionBanner = () => {
    const [banners, setBanners] = useState(null);
    const navigate = useNavigate();


    useEffect(() => {
        // axios({
        //     method: "get",
        //     withCredentials: true,
        //     url: `api/banners/get-all-banner`,
        // })
        const host = BASE_URL;
        api(host)
            .get(`book_of_trips/banners/get-all-banner`)
            .then((res) => {
            setBanners(res.data.data)
        }).catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [])

    var settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 1000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        banners?.length > 0 &&
        <div className='container-fluid promotional__banner'>
            <Slider {...settings}>
                {
                    banners?.map(obj => {
                        return <Link key={obj.id} to={`category/${obj.location?.replace(/\s+/g, '-')}`}>
                            <img src={obj.image} alt="banner" />
                        </Link>
                    })
                }
            </Slider>
        </div>
    )
}

export default PromotionBanner
