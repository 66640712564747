import React from 'react'
import '../../../css/Style/SingleTrip/Accordion.css'
import { useNavigate } from 'react-router-dom'

const Accordion = ({ data, trip_data, custom_trip }) => {
    const navigate = useNavigate();
    const liveDates = trip_data?.dates?.filter((obj) => {
        return new Date(obj?.date) >= new Date()
    });

    // taking out unique months from all the dates
    const uniqueMonths = Array.from(new Set(liveDates?.map(obj => new Date(obj?.date).toLocaleString('default', { month: 'long' })))).sort((a, b) => new Date(Date.parse('01 ' + a + ' 2000')) - new Date(Date.parse('01 ' + b + ' 2000')));

    const today = new Date();
    const currentMonthIndex = today.getMonth();
    const bookingMonths = uniqueMonths?.filter((month, index) => {
        return new Date(`${month} 1,2000`).getMonth() >= currentMonthIndex;
    })

    const bookingHandler = (id) => {
        navigate(`/book/${id}`)
    }

    function formatString(str) {
        // Replace **text** with <strong>text</strong>
        return str.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    }

    return (
        <>
            {
                data?.length > 0 && 
                    <div className=''>
                        <h2 className='Mon_font'>Day Wise Plan</h2>
                        <div className="hr-line mb-3 m-0"></div>
                    </div>
            }
            <div className="Mon_font accordion accordion-flush mb-4" id="accordionFlushExample">
                {
                    data?.map((item, index) => {
                        return <div key={index} className="accordion-item">
                            <div className='d-flex justify-content-center accordion-item-div'>
                                <div className='accordian-item-day'>Day {data.indexOf(item) + 1}</div>
                                <button className="bg-light accordion-button collapsed px-0 accordian-item-button pe-2" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-${index}`} aria-expanded="true" aria-controls={`collapse-${index}`}>
                                    <small><b>{item.heading}</b></small>
                                </button>
                            </div>
                            <div id={`collapse-${index}`} className="accordion-collapse collapse" aria-labelledby={`heading-${index}`} data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body border m-2">
                                    {item.description.split('\n').map((line, i) => (
                                        <div key={i} className='my-2'>
                                            <strong className='accordian-item-dot'>• </strong>
                                            <span className='accordian-item-desc' dangerouslySetInnerHTML={{ __html: formatString(line) }}></span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
            {
                custom_trip === false && bookingMonths?.length > 0 &&
                    <div className="book__now__button d-flex justify-content-center align-items-center">
                        <button className='mt-3' onClick={() => bookingHandler(trip_data?.id)}>Book Now</button>
                    </div>
            }
        </>
    )
}

export default Accordion
