import axios from 'axios';
import { useState } from 'react';
import api from "../../api";
import {BASE_URL} from "../../constant";

const SearchBookingList = ({setProduct, getAllData}) => {
    const [id, setId] = useState(null)

    // get booking list by sequence id
    const onSubmit = async (e) => {
        e.preventDefault()
        // axios({
        //     method: "post",
        //     data: {
        //         id: id
        //     },
        //     withCredentials: true,
        //     url: `book_of_trips/booking/get-booking-by-id`,
        // })
        api(BASE_URL).post(`/book_of_trips/booking/get-booking-by-id`,
            {
                id: id,
            },
        ).then((res) => {
            const button = document.getElementById("close-search-trip-bar");
            button.click()
            setProduct(res.data.data)
        })
        .catch((err) => {
            console.log(err);
        });
    }

  return (
    <div className='Mon_font'>
        <button className="py-2 px-3 btn btn-warning btn-sm" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"><i className="me-2 fa-solid fa-magnifying-glass"></i>Search Booking</button>
        <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
            <div className="offcanvas-header bg-warning">
                <h5 id="offcanvasRightLabel">Search Booking By ID</h5>
                <button type="button" id="close-search-trip-bar" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <hr className='m-0 p-0'/>
            <div className="offcanvas-body" style={{backgroundColor: '#f5f5f5'}}>
                <form className='row gap-3 p-3' onSubmit={onSubmit}>
                    <input required placeholder='booking id' className='form-control' type="text" onChange={(e)=>{setId(e.target.value)}}/>
                    <div className='col-12 text-center'>
                        <button type='submit' className='btn btn-warning text-center mt-3 w-50'>Search</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
  )
}

export default SearchBookingList
