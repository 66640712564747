import axios from "axios";
import { message } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "../../redux/features/LoadingSlice";
// import loginBg from "../../images/login-bg.png";
import "../../../css/Style/Admin/AdminLogin.css";
import {BASE_URL} from "../constant";
import api from "../api";

const AdminLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(false);
  const [email, setEmail] = useState(null);
  const [pass, setPass] = useState(null);

  useEffect(() => {
    // axios({
    //   method: "get",
    //   withCredentials: true,
    //   url: `${BASE_URL}/book_of_trips/users/isLogin`,
    // })

    api(BASE_URL)
        .get(`book_of_trips/users/isLogin`)
      .then((res) => {
        setIsLogin(false);
        if (res.data.success) {
          navigate("/admin-dashboard");
        }
      })
      .catch((err) => {
        setIsLogin(true);
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(showLoading());

    // axios({
    //   method: "POST",
    //   data: {
    //     email,
    //     pass,
    //   },
    //   // withCredentials: true,
    //   url: `${BASE_URL}/book_of_trips/users/login`,
    // })

    api(BASE_URL).post('book_of_trips/users/login', {email,pass})
      .then((res) => {
        setTimeout(() => {
          dispatch(hideLoading());
        }, 1500);
        if (res.data.success) {
          let token = res.data.auth_key;
          window.localStorage.setItem('auth_key', token);
          console.log("auth_key", token)
          navigate("/admin-dashboard");
        }
        message.success(res.data.message);
      })
      .catch((err) => {
        setTimeout(() => {
          dispatch(hideLoading());
        }, 1500);
        console.log(err);
      });
  };
  return (
    <>
      {isLogin && (
        <div
          className="form__body Mon_font"
          style={{
            // backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.7) 100%), url(${loginBg})`,
          }}
        >
          <form
            onSubmit={handleSubmit}
            className="border text-white p-5 rounded"
          >
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Email address
              </label>
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder="Enter email"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputPassword1" className="form-label">
                Password
              </label>
              <input
                type="password"
                className="form-control"
                id="exampleInputPassword1"
                onChange={(e) => setPass(e.target.value)}
                required
                placeholder="Enter password"
              />
            </div>
            <button type="submit" className="btn btn-warning d-block m-auto">
              Login
            </button>
          </form>
        </div>
      )}
    </>
  );
};

export default AdminLogin;
