import axios from "axios";
import qs from "query-string";
import * as constant from "./constant";
import {BASE_URL} from "./constant";

function getAuthHeader() {
    return `${window.localStorage.getItem("auth_key")}`;
}
export default function api( baseUrl) {
    return createAxios(baseUrl);

};

function createAxios(baseUrl) {
    const headers = {};
    headers["authorization"] = getAuthHeader();

    return axios.create({
        baseURL: baseUrl,
        headers,
        withCredentials: true,
    });
}
