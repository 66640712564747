import React from 'react'

const ContactForm = () => {
    return (
        <div className="contact__form shd mt-5 d-none d-md-block d-lg-block">
            <p className='Mon_font text-start text-center'><small>Still have Doubts? We will call you back</small></p>
            <div>
                <h2 className='Mon_font text-center'>Get in touch</h2>
                <div className="hr-line mb-3"></div>
            </div>
            <form>
                <div className="mb-3">
                    <label htmlFor="FullName" className="form-label">Full Name</label>
                    <input type="email" className="form-control" aria-describedby="emailHelp" />
                </div>
                <div className="mb-3">
                    <label htmlFor="Contact" className="form-label">Contact Number</label>
                    <input type="text" className="form-control" aria-describedby="emailHelp" />
                </div>
                <div className="mb-3">
                    <label htmlFor="Email" className="form-label">Your Email</label>
                    <input type="text" className="form-control" aria-describedby="emailHelp" />
                </div>
                <div className="mb-3">
                    <label htmlFor="Destination" className="form-label">Your Destination</label>
                    <input type="text" className="form-control" aria-describedby="emailHelp" />
                </div>
                <button type="submit" className="btn btn-warning d-block m-auto">Submit</button>
            </form>
        </div>
    )
}

export default ContactForm
