import { createSlice } from "@reduxjs/toolkit";

export const tripSlice = createSlice({
    name: "trips",
    initialState: {
        trip: null
    },
    reducers: {
        SetTrip: (state, action) => {
            state.trip = action.payload;
          },
    }
})

export const { SetTrip } = tripSlice.actions;

export default tripSlice.reducer;