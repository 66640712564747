import React, { useEffect, useState } from 'react';
import Layout from '../../component/Admin/AdminLayout';
import AdminHeader from './component/AdminHeader';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Pagination from './component/Pagination';
import { message } from 'antd';
import api from "../api";
import {BASE_URL} from "../constant";
// import noresult from '../../images/noresult.png';

const Inquiry = () => {
    const [inquiry, setInquiry] = useState(null);
    const [searchDate, setSearchDate] = useState(null)
    const [status, setStatus] = useState(null);
    const [modalMsg, setModalMsg] = useState(null);
    const [totalData, setTotalData] = useState(null)
    const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    
    //pagination part
    const [currentPage, setCurrentPage] = useState(1);
    const [inquiryPerPage, setInquiryPerPage] = useState(8);

    const indexOfLastInquiry = currentPage * inquiryPerPage ;
    const indexOfFirstInquiry = indexOfLastInquiry - inquiryPerPage;

    const currentInquiry = inquiry?.slice(indexOfFirstInquiry, indexOfLastInquiry);
    const totalInquiry = inquiry?.length;

    // if you want to change inquiry status
    const updateInquiry = (id, value)=>{
        // axios({
        //     method: "POST",
        //     data: {
        //         id: id,
        //         status: value,
        //     },
        //     withCredentials: true,
        //     url: `book_of_trips/inquiry/update-inquiry`,
        // })
        api(BASE_URL).post(`book_of_trips/inquiry/update-inquiry`, {
                    id: id,
                    status: value,
                })
            .then((res) => {
            if(status === null){
                getAllInquiry()
            } else {
                if(value === 'deleted'){
                    getAllStatusInquiry(status)
                }
                if(value === 'completed'){
                    getAllStatusInquiry('pending')
                }
            }
            message.success(res.data.message)
        }).catch((err) => {
            console.log(err);
        });
    }

    //to upper case function
    const toUpperCase = (string) =>{
        return string?.charAt(0).toUpperCase() + string?.slice(1);
    }

    //get all inquiry
    const getAllInquiry = ()=>{
        // axios({
        //     method: "get",
        //     withCredentials: true,
        //     url: `book_of_trips/inquiry/get-all-inquiry`,
        // })
        api(BASE_URL)
            .get(`book_of_trips/inquiry/get-all-inquiry`)
            .then((res) => {
            let arr = res.data.data
            let newArr = arr.reverse()

            if(searchDate){
                var getFilterDate = newArr?.filter((inquiry)=>{
                    let date = new Date(inquiry.date)
                    return console.log(date === searchDate)
                })
                setInquiry(getFilterDate)
                setTotalData(getFilterDate?.length)
                setSearchDate(null)
                setStatus(null)
            } else{
                setInquiry(newArr)
                setTotalData(newArr?.length)
                setStatus(null)
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    //on handle click inquiry 
    const onHandleInquiry = (value)=>{
        setStatus(value)
    }

    // if inquiry status changes
    const getAllStatusInquiry = (status)=>{
        // axios({
        //     method: "POST",
        //     data: {
        //         status: status
        //     },
        //     withCredentials: true,
        //     url: `book_of_trips/inquiry/get-status-inquiry`,
        // })
            api(BASE_URL).post(`book_of_trips/inquiry/get-status-inquiry`, {
                status: status
            })
            .then((res) => {
            let arr = res.data.data
            let newArr = arr.reverse()
            setInquiry(newArr) 
            setTotalData(newArr?.length) 
        }).catch((err) => {
            console.log(err);
        });
    }

    //if you want inquiry status deleted permanently
    const deleteInquiry = (id)=>{
        // axios({
        //     method: "POST",
        //     data: {
        //         id: id,
        //     },
        //     withCredentials: true,
        //     url: `book_of_trips/inquiry/delete-inquiry`,
        // })
        api(BASE_URL).post(`/book_of_trips/inquiry/delete-inquiry`,
            {
                id: id,
            },
        ).then((res) => {
            if(status === null){
                getAllInquiry()
            } else {
                getAllStatusInquiry(status);
            }
            message.success(res.data.message)
        }).catch((err) => {
            console.log(err);
        });
    }
    
    //use Effect hook function
    useEffect(()=>{
        if(status === null){
            getAllInquiry()
        } else if(status){
            getAllStatusInquiry(status)
        } else if(searchDate){
            getAllInquiry()
        }
        // eslint-disable-next-line
    }, [status, searchDate])

    
  return (
    <Layout>
        <AdminHeader title='Manage Inquiries' redirect='/admin-dashboard'/>
        <div className="container Mon_font px-4 pb-4">
            <div className='py-3 d-flex justify-content-between align-items-center'>
                <div className='d-flex gap-2 justify-content-between align-items-center'>
                    <div className="btn-group d-flex justify-content-center align-items-center">
                        <p className='m-0 px-3 py-2 bg-warning'>Total Inquiry:</p>
                        <p className='bg-info m-0 px-3 py-2'>{totalData? totalData : '0'}</p>
                    </div>
                    {/* <div>
                        <input className="form-control py-2 bg-info" name='date' type="date" placeholder='select date' onChange={(e)=>{setSearchDate(e.target.value)}}/>
                    </div> */}
                </div>
                <div className="btn-group bg-warning">
                    <button className="btn btn-secondary dropdown-toggle bg-transparent border-0 text-black px-4" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {status? toUpperCase(status) : 'All'} Inq
                    </button>
                    <ul className="dropdown-menu">
                        <li><Link className="dropdown-item" to="#" onClick={()=>{onHandleInquiry('pending')}}>Pending Inquires</Link></li>
                        <li><Link className="dropdown-item" to="#" onClick={()=>{onHandleInquiry('completed')}}>Completed Inquires</Link></li>
                        <li><Link className="dropdown-item" to="#" onClick={()=>{onHandleInquiry('deleted')}}>Deleted Inquires</Link></li>
                        <li><Link className="dropdown-item" to="#" onClick={getAllInquiry}>All Inquires</Link></li>
                    </ul>
                </div>
            </div>
            {currentInquiry?.length === 0?
                <div className='d-flex flex-column justify-content-center align-items-center mt-5'>
                    {/*<img src={noresult} alt="" width={150}/>*/}
                    <h3>No Record Found</h3>
                </div> :
                <div className="w-100 overflow-x">
                <table className='table table-light table-bordered m-0 mt-4 txt-xl'>
                    <thead className='p-2 py-3 table-secondary'>
                        <tr>
                            <th scope="col" className='py-2'>#</th>
                            <th scope="col" className='py-2 pe-5 ps-3'>Date</th>
                            <th scope="col" className='py-2 px-5'>Name</th>
                            <th scope="col" className='py-2'>Phone</th>
                            <th scope="col" className='py-2'>Email Id</th>
                            <th scope="col" className='py-2'>Message</th>
                            <th scope="col" className='py-2'>Status</th>
                            <th scope="col" className='py-2'>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                            {currentInquiry?.map((inquiry, index)=>{
                            return <tr className='' key={inquiry.id}>
                                    
                                    <td className=''>{index + 1}</td>
                                    <td className=''>{new Date(inquiry.date).getDate() + " " + month[new Date(inquiry.date).getMonth()] + " " + new Date(inquiry.date).getFullYear()}</td>
                                    <td className=''>{inquiry.name}</td>
                                    <td className=''>{inquiry.phone}</td>
                                    <td className='txt-sm'>{inquiry.email}</td>
                                    <td className=''>
                                        <button
                                            className={`btn txt-sm btn-${(inquiry.status === 'pending' && 'warning') || (inquiry.status === 'completed' && 'success') || (inquiry.status === 'deleted' && 'danger')} btn-sm`}
                                            data-bs-toggle="modal" data-bs-target="#exampleModal"
                                            onClick={()=>{setModalMsg(inquiry.message)}}
                                        >
                                            View Message
                                        </button>
                                        <div className="modal" tabIndex="-1" id="exampleModal">
                                            <div className="modal-dialog">
                                                <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">Inquiry Message</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>{setModalMsg(inquiry.message)}}></button>
                                                </div>
                                                <div className="modal-body">
                                                    <p style={{ overflowWrap: 'break-word' }}>{modalMsg}</p>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal" onClick={()=>{setModalMsg(null)}}>Close</button>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    {inquiry.status === 'pending' &&
                                        <td className='ps-4 border-warning' >
                                            <button className="btn dropdown-toggle bg-transparent text-warning px-4 txt-lg border-warning" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                             {toUpperCase(inquiry.status)}
                                            </button>
                                            <ul className="dropdown-menu">
                                                <li><Link className="dropdown-item txt-lg" to="#" onClick={() => updateInquiry(inquiry.id, 'completed')}>Completed</Link></li>
                                            </ul>
                                        </td>
                                    }
                                    {inquiry.status === 'completed' &&
                                        <td className='ps-4 border-success'>
                                             <p className="bg-transparent text-success px-4 txt-lg m-0" aria-expanded="false">
                                             {toUpperCase(inquiry.status)}
                                            </p>
                                        </td>
                                    }
                                    {(inquiry.status === 'deleted') &&
                                        <td className='ps-4 border-danger'>
                                             <p className="bg-transparent text-danger px-4 txt-lg m-0" aria-expanded="false">
                                             {toUpperCase(inquiry.status)}
                                            </p>
                                        </td>
                                    }
                                    <td className='ps-4'>
                                        {inquiry.status !== 'deleted'?
                                            <div className="flex gap-2">
                                                <i className="fa-sharp fa-solid fa-trash cursor-pointer p-2 text-dark"  onClick={() => updateInquiry(inquiry.id, 'deleted')}></i>
                                            </div> : 
                                            <div className="flex gap-2">
                                                <i className="fa-sharp fa-solid fa-trash cursor-pointer p-2 text-danger"  onClick={() => deleteInquiry(inquiry.id,)}></i>
                                            </div>
                                        }
                                    </td>
                            </tr>
                            })
                            }
                    </tbody>
                </table>
            </div>}
            {currentInquiry?.length !== 0 && <Pagination totalInquiry={totalInquiry} setCurrentPage={setCurrentPage} inquiryPerPage={inquiryPerPage} currentPage={currentPage}/>}
        </div>
    </Layout>
  )
}

export default Inquiry
