import React, { useState } from "react";
import PopupForm from "../PopupForm";
// import ctaimage from "../../images/LandingPage/cta-image.png";
// import expert_bg from "../../images/LandingPage/expert_bg.jpg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import "../../../css/Style/LandingPage/TalkToExpert.css";
import "../../../css/Style/PopupForm.css";
import PopupFormBootstrap from "../PopupFormBootstrap";

const TalkToExpert = () => {
  return (
    <>
      <div className="Mon_font talk__to__bg mt-2 bg-warning">
        <div className="container image__button">
          <div className="row">
            <div className="m-0 d-block m-auto d-md-none d-lg-none col-12 col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center align-items-center">
              {/* Mobile Image  */}
              {/*<img className="m-0 p-2" width={180} src={ctaimage} alt="" />*/}
            </div>
            <div className="m-0 col-12 col-sm-12 col-md-6 col-lg-6 d-flex flex-column justify-content-center align-items-start">
              <h2 className="m-0">In Doubt ? Can't Decide?</h2>
              <p1>Don't hesitate & hand over your queries to our travel experts and let them assist you with their authentic traveling insights.</p1>
              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                Reach Out To Us
              </button>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 d-flex flex-column justify-content-center align-items-end">
              <img
                className="d-none d-md-block d-lg-block"
                width={280}
                // src={ctaimage}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TalkToExpert;
