import React, { useEffect, useState } from 'react';
import Layout from '../../component/Admin/AdminLayout';
import AdminHeader from './component/AdminHeader';
import { message } from 'antd';
import axios from 'axios';
import {BASE_URL} from "../constant";
import api from "../api";

const HomeBanner = () => {
    const [loading, setLoading] = useState(false);
    const [inputFields, setInputFields] = useState(null);
    const [homeBanner, setHomeBanner] = useState(null);


    useEffect(()=>{
        // getAllCategory()
        getHomeBanner()
        // eslint-disable-next-line
    }, [])


    // banner image cloudinary API
    const postDetails = (image) => {
        if (!image) {
            return message.error('Please Select an image');
        }
        if (image.type === 'image/jpeg' || image.type === 'image/png') {
            const data = new FormData();
            data.append('file', image);
            setLoading(true)
            const headers = {};
            headers["authorization"] = `${window.localStorage.getItem("auth_key")}`

            fetch(`${BASE_URL}/book_of_trips/admin/upload`, {
                method: 'post',
                body: data,
                headers: headers,
            }).then((res) => res.json()).then((data) => {
                setLoading(false)
                setInputFields({ ...inputFields, image: data.secure_url })
            }).catch((err) => {
                message.error(err);
            })
        } else {
            return message.error('Please Select an image');
        }
    };

    // on form value change
    const handleChange = (event, value) => {
        if (value === 'bannerImage') {
            postDetails(event.target.files[0])
        }
        setInputFields({ ...inputFields, [event.target.name]: event.target.value })
    }

    //on form submit
    const submitHandler = (e) => {
        e.preventDefault();
        setLoading(true)

            api(BASE_URL).post(`book_of_trips/homecategory/update-home-banner`,{
                image: inputFields.image,
            }).then((res) => {
            getHomeBanner()
            setTimeout(() => {
                setLoading(false)
            }, 1500);
            message.success(res.data.message)
        }).catch((err) => {
            setTimeout(() => {
                setLoading(false)
            }, 1500);
            console.log(err);
        });
    }


    const getHomeBanner = () => {

         api(BASE_URL)
            .get(`book_of_trips/get-home-banner`)
            .then((res) => {
            setHomeBanner(res.data.data)
            setInputFields({...inputFields, image: res.data.data})
                // console.log(res.data.data)
        }).catch((err) => {
            console.log(err);
        });
    }
    return (
        <Layout>
            <AdminHeader title='Home Banner' redirect='/admin-dashboard'/>
            <div className="container Mon_font px-4">
                <form className="" style={{ backgroundColor: "#fff" }} onSubmit={submitHandler}>
                    <div className="col-3 my-3">
                        <label htmlFor="tripImage" className="form-label">Add Banner</label>
                        <input required type="file" accept="image/*" className="form-control" id="tripImage" onChange={(event) => { handleChange(event, 'bannerImage') }} />
                        <span className='text-secondary'><small>Banner size - 1350x375px</small></span>
                        {inputFields?.image && <div className="col-4 my-2">
                            <img width={340} src={inputFields.image} alt="Loading" />
                        </div>}
                    </div>
                    <div className='my-2'>
                        <button disabled={loading === true} type='submit' className='btn btn-warning w-100 '>{loading ? 'Loading...' : 'Submit'}</button>
                    </div>
                </form>
            </div>

            {/* all banners */}
            {/*<div className="container Mon_font px-4 pb-4">*/}
            {/*    <table className='table table-light table-bordered w-100 m-0 mt-4'>*/}
            {/*        <thead className='p-2 py-3 table-secondary'>*/}
            {/*        </thead>*/}
            {/*    </table>*/}
            {/*</div>*/}
        </Layout>
    )
}

export default HomeBanner
