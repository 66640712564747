import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Select from "react-select";
import api from "../../api";
import {BASE_URL} from "../../constant";
import {getAllTrips} from "../../../util/FetchTrips";

const SearchTrip = ({setProduct, getAllData}) => {
    const [data, setData] = useState(null)
    const [selectedOption, setSelectedOption] = useState(null);
    const tripHeadings = data?.map((tripItem) => ({
        value: tripItem.heading,
        label: tripItem.heading,
    }));

    const handleSelect = (selectedOption) => {
        setSelectedOption(selectedOption);
    };

    // get all trips
    const AllTrips = async () => {
        await getAllTrips(BASE_URL).then((res) => {
            setData(res);
        }).catch((err) => {
            console.log(err);
        });
    }
    useEffect(() => {
        if (data === null) {
            AllTrips();
        }
        // eslint-disable-next-line
    }, [])

    useEffect(()=>{
        if(selectedOption){
            setProduct(data?.filter((tripItem) => tripItem.heading === selectedOption.value));
            const button = document.getElementById("close-search-trip-bar");
            button.click()
            setSelectedOption(null)
        }
        // eslint-disable-next-line
    }, [selectedOption])
  return (
    <div className='Mon_font'>
        <button className="py-2 px-3 btn btn-warning btn-sm" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"><i className="me-2 fa-solid fa-magnifying-glass"></i>Search Trip</button>
        <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
            <div className="offcanvas-header bg-warning">
                <h5 id="offcanvasRightLabel">Search Trip With Title</h5>
                <button type="button" id="close-search-trip-bar" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <hr className='m-0 p-0'/>
            <div className="offcanvas-body " style={{backgroundColor: '#f5f5f5'}}>
                <div className='' style={{height: '400px'}}>
                    <Select options={tripHeadings} value={selectedOption} onChange={handleSelect} placeholder="Trip Title" isSearchable/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SearchTrip
